.el-scrollbar[data-v-38fdef00] {
  height: 100%;
}
.margin-r-10[data-v-38fdef00] {
  margin-right: 10px;
}
.box .el-scrollbar__wrap[data-v-38fdef00] {
  overflow: scroll;
}
[data-v-38fdef00] .el-pagination {
  text-align: right;
}
.margin-t-20[data-v-38fdef00] {
  margin-top: 20px;
}
.margin-b-14[data-v-38fdef00] {
  margin-bottom: 14px;
}
.border-left[data-v-38fdef00] {
  border-left: 1px solid #cccccc;
}
[data-v-38fdef00] .UserDeptMultipleTree .el-tree {
  height: 490px !important;
  max-height: 490px !important;
}
.footerBtn[data-v-38fdef00] {
  border-top: 1px solid #cccccc;
  padding-top: 10px;
}
[data-v-38fdef00] .el-dialog {
  margin-bottom: 0px !important;
}